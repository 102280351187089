import { z } from "zod";
import { assetSchema } from "@models/asset";
export var moodSchema = z.object({
    slug: z.string(),
    display_name: z.string(),
    icon: assetSchema,
    small_icon: assetSchema,
});
export var getMoodsResponseSchema = z.object({
    moods: z.array(moodSchema),
});
export var getMoodsParamsSchema = z.object({
    slug: z.array(z.string()).optional(),
});
