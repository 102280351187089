import { z } from "zod";
export var staticContentKeySchema = z.enum([
    "privacy-policy",
    "terms-and-conditions",
]);
export var staticContentSchema = z.object({
    content_key: staticContentKeySchema,
    content: z.string(),
});
export var getStaticContentResponseSchema = z.object({
    static_content: staticContentSchema,
});
