import { z } from "zod";
import { paginationParamsSchema } from "@models/pagination";
export var distillerySchema = z.object({
    slug: z.string(),
    display_name: z.string(),
});
export var getDistilleriesParamsSchema = z
    .object({
    slug: z.array(z.string()).optional(),
    q: z.string().optional(),
})
    .merge(paginationParamsSchema);
export var getDistilleriesResponseSchema = z.object({
    distilleries: z.array(distillerySchema),
    cursor: z.number().int().nullable(),
});
