var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import cn from "classnames";
import { LocalisedText } from "@components/i18n/LocalisedText";
import BrandLogo from "./BrandLogo";
var ContactUsLink = function () {
    if (!window.appConfig.contactEmail) {
        return (_jsx("span", __assign({ className: cn("block", "text-white", "text-title-s", "uppercase", "tracking-widest") }, { children: _jsx(LocalisedText, { id: "footer.contactUs" }, void 0) }), void 0));
    }
    return (_jsx("a", __assign({ href: "mailto:" + window.appConfig.contactEmail, className: cn("block", "text-white", "text-title-s", "uppercase", "tracking-widest") }, { children: _jsx(LocalisedText, { id: "footer.contactUs" }, void 0) }), void 0));
};
var DesktopFooterImpl = function () {
    return (_jsx("div", __assign({ className: cn("hidden", "xl:flex", "bg-black", "w-screen", "h-20") }, { children: _jsxs("div", __assign({ className: cn("flex", "justify-between", "items-center", "w-full", "max-w-320", "mx-auto", "px-16") }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsx(BrandLogo, { className: cn("w-10") }, void 0),
                        _jsx("div", __assign({ className: cn("text-green text-base leading-5 font-black", "uppercase tracking-widest", "w-[6.5rem] -my-1 ml-6") }, { children: _jsx(LocalisedText, { id: "common.siteName" }, void 0) }), void 0)] }), void 0),
                _jsxs("div", __assign({ className: "text-right" }, { children: [_jsx("div", __assign({ className: cn("flex", "justify-end") }, { children: _jsx(ContactUsLink, {}, void 0) }), void 0),
                        _jsx("span", __assign({ className: cn("block", "text-white", "font-bold", "text-[0.625rem]", "leading-[0.8125rem]", "tracking-widest", "mt-1") }, { children: _jsx(LocalisedText, { id: "footer.copyright" }, void 0) }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export var DesktopFooter = React.memo(DesktopFooterImpl);
export default DesktopFooter;
