import { z } from "zod";
import { paginationParamsSchema } from "@models/pagination";
export var originSchema = z.object({
    slug: z.string(),
    display_name: z.string(),
});
export var getOriginsParamsSchema = z
    .object({
    slug: z.array(z.string()).optional(),
    q: z.string().optional(),
})
    .merge(paginationParamsSchema);
export var getOriginsResponseSchema = z.object({
    origins: z.array(originSchema),
    cursor: z.number().int().nullable(),
});
