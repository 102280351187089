import { z } from "zod";
import { date } from "@models/date";
import { assetSchema } from "@models/asset";
import { paginationParamsSchema } from "@models/pagination";
import { thumbnailSchema } from "@models/thumbnail";
import { userSchema } from "@models/user";
export var beverageFlavourScoreSchema = z.object({
    beverage_id: z.number(),
    mood: z.string(),
    woody_score: z.number(),
    spicy_score: z.number(),
    cereal_score: z.number(),
    fruity_score: z.number(),
    floral_score: z.number(),
    peaty_score: z.number(),
    tier: z.string().nullable(),
});
export var beveragePhotoSchema = z.object({
    type: z.enum(["icon", "note-photo"]),
    asset: assetSchema,
    thumbnails: z.array(thumbnailSchema),
});
export var beverageSchema = z.object({
    id: z.number(),
    name: z.string(),
    subtitle: z.string(),
    distillery: z.string(),
    origin: z.string(),
    category: z.string(),
    year: z.number().nullable(),
    age: z.number().nullable(),
    description: z.string(),
    abv: z.number().nullable(),
    volume: z.string().nullable(),
    suggested_price: z.string().nullable(),
    photo: beveragePhotoSchema.nullable(),
    overall_score: beverageFlavourScoreSchema.nullable(),
    updated_at: date.nullable(),
    updated_by: userSchema.nullable(),
});
export var getBeverageByIdResponseSchema = z.object({
    beverage: beverageSchema,
});
export var getBeveragesResponseSchema = z.object({
    beverages: z.array(beverageSchema.nullable()),
});
export var getBeverageScoresSchema = z.object({
    scores: z.array(beverageFlavourScoreSchema),
    my_score: beverageFlavourScoreSchema.nullable(),
});
export var searchBeveragesQuerySchema = z
    .object({
    mood: z.string(),
    woody_score: z.number(),
    spicy_score: z.number(),
    peaty_score: z.number(),
    fruity_score: z.number(),
    floral_score: z.number(),
    cereal_score: z.number(),
    tier: z.string().optional(),
    distillery: z.string().optional(),
    origin: z.string().optional(),
    age: z.number().optional(),
    year: z.number().optional(),
    custom_flavor_id: z.array(z.number()).optional(),
})
    .merge(paginationParamsSchema);
export var searchBeveragesResponseSchema = z.object({
    items: z.array(beverageSchema),
    total: z.number(),
    cursor: z.number().nullable(),
});
export var searchBeveragesByNameQuerySchema = z
    .object({
    search_mode: z.literal("name"),
    q: z.string(),
})
    .merge(paginationParamsSchema);
export var searchBeveragesByNameResponseSchema = z.object({
    beverages: z.array(beverageSchema),
});
export var updateBeverageDescriptionRequestSchema = z.object({
    description: z.string(),
});
export var updateBeverageDescriptionResponseSchema = z.object({
    description: z.string(),
});
