var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { WebContainer, } from "@authgear/web";
import { OAuthRedirectPath, GuestPromotionRedirectPath } from "@router/routes";
import { encodeOAuthState } from "@utils/login";
var APIClient = /** @class */ (function () {
    function APIClient() {
        this.authgearClientID = window.appConfig.authgearClientID;
        this.authgearEndpoint = window.appConfig.authgearEndpoint;
        this.authgearContainer = new WebContainer();
    }
    APIClient.prototype.getAuthgearContainer = function () {
        return this.authgearContainer;
    };
    APIClient.prototype.fetchWithAuthgear = function (input, init) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.authgearContainer.fetch(input, init)];
            });
        });
    };
    APIClient.prototype.configure = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.authgearContainer.configure({
                        clientID: this.authgearClientID,
                        endpoint: this.authgearEndpoint,
                    })];
            });
        });
    };
    // NOTE: For listening to session state change
    APIClient.prototype.setAuthgearContainerDelegate = function (delegate) {
        this.authgearContainer.delegate = delegate !== null && delegate !== void 0 ? delegate : undefined;
    };
    APIClient.prototype.openAuthgearSettings = function () {
        var params = new URLSearchParams();
        var queryString = params.toString();
        var authgearSettingURL = queryString !== ""
            ? this.authgearEndpoint + "/settings?" + queryString
            : this.authgearEndpoint + "/settings";
        window.open(authgearSettingURL, "_blank");
    };
    APIClient.prototype.getAuthgearAccessToken = function () {
        var _a;
        return (_a = this.authgearContainer.accessToken) !== null && _a !== void 0 ? _a : null;
    };
    APIClient.getAuthgearAuthorizeOptions = function (initialPage, prompt) {
        var redirectURI = "" + window.location.origin + OAuthRedirectPath.render();
        var originalPath = "" + window.location.pathname + window.location.search;
        var options = {
            redirectURI: redirectURI,
            page: initialPage,
            // Localisation handling TBD
            // uiLocales: this.locale != null ? [this.locale] : undefined,
            state: encodeOAuthState({
                originalPath: originalPath,
            }),
        };
        if (prompt !== undefined) {
            if (prompt !== null) {
                options.prompt = prompt;
            }
        }
        else {
            options.prompt = "login";
        }
        return options;
    };
    APIClient.prototype.showAuthUI = function (initialPage, prompt) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.authgearContainer.startAuthorization(APIClient.getAuthgearAuthorizeOptions(initialPage, prompt))];
            });
        });
    };
    // For handling redirect to Authgear signup page
    // instead of push to navigation stack
    APIClient.prototype.makeAuthUrl = function (initialPage, prompt) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.authgearContainer.makeAuthorizeURL(APIClient.getAuthgearAuthorizeOptions(initialPage, prompt))];
            });
        });
    };
    APIClient.prototype.logout = function (redirectURI) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.authgearContainer.logout({ force: true, redirectURI: redirectURI })];
            });
        });
    };
    APIClient.prototype.signupAnonymously = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.authgearContainer.authenticateAnonymously()];
            });
        });
    };
    APIClient.prototype.promoteAnonymousUser = function (redirectPath) {
        return __awaiter(this, void 0, void 0, function () {
            var originalPath, uri;
            return __generator(this, function (_a) {
                originalPath = redirectPath !== null && redirectPath !== void 0 ? redirectPath : "" + window.location.pathname + window.location.search;
                uri = "" + window.location.origin + GuestPromotionRedirectPath.render();
                return [2 /*return*/, this.authgearContainer.startPromoteAnonymousUser({
                        redirectURI: uri,
                        state: encodeOAuthState({
                            originalPath: originalPath,
                        }),
                    })];
            });
        });
    };
    return APIClient;
}());
export { APIClient };
