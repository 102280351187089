var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useStaticContentAPI } from "@api/staticContentAPI";
import LoadingSpinner from "@components/common/LoadingSpinner";
import { UnknownErrorView } from "@components/error/UnknownErrorView";
import styles from "./StaticContent.module.scss";
export var StaticContent = React.memo(function (props) {
    var className = props.className, contentKey = props.contentKey;
    var getStaticContent = useStaticContentAPI().getStaticContent;
    var _a = useState({ state: "idle" }), staticContentState = _a[0], setStaticContentState = _a[1];
    useEffect(function () {
        getStaticContent(contentKey)
            .then(function (resp) {
            setStaticContentState({
                state: "loaded",
                data: resp.static_content.content,
            });
        })
            .catch(function (err) {
            console.error(err);
            setStaticContentState({ state: "error", error: "unknown" });
        });
    }, [contentKey, getStaticContent]);
    return (_jsxs("div", __assign({ className: className }, { children: [(staticContentState.state === "idle" ||
                staticContentState.state === "loading") && (_jsx("div", __assign({ className: cn("w-full") }, { children: _jsx(LoadingSpinner, { className: cn("w-20", "h-20", "mx-auto") }, void 0) }), void 0)),
            staticContentState.state === "loaded" && (_jsx("div", { className: cn("mb-8", styles["container"]), dangerouslySetInnerHTML: {
                    __html: staticContentState.data,
                } }, void 0)),
            staticContentState.state === "error" && (_jsx("div", __assign({ className: cn("h-full", "flex", "items-center justify-center") }, { children: _jsx(UnknownErrorView, {}, void 0) }), void 0))] }), void 0));
});
