import { z } from "zod";
import { assetSchema } from "@models/asset";
export var tierSchema = z.object({
    slug: z.string(),
    display_name: z.string(),
    criteria: z.number().int(),
    color: z.string(),
    is_superior: z.boolean(),
    icon: assetSchema,
});
export var getTiersResponseSchema = z.object({
    tiers: z.array(tierSchema),
});
