var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { LocaleProvider } from "@oursky/react-messageformat";
import enJson from "../i18n/en.json";
import { Locale } from "@models/locale";
var messageByLocale = {
    en: enJson,
};
export var I18nProvider = function (props) {
    var children = props.children;
    var locale = useState(Locale.en)[0];
    return (_jsx(LocaleProvider, __assign({ locale: locale, messageByID: messageByLocale[locale] }, { children: children }), void 0));
};
