var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import cn from "classnames";
import BrandLogo from "@components/common/BrandLogo";
import { LocalisedText } from "@components/i18n/LocalisedText";
import { assetURL } from "@utils/assets";
import DesktopFooter from "./DesktopFooter";
var StaticContentDesktopHeader = React.memo(function () {
    return (_jsx("div", __assign({ className: cn("hidden", "xl:block", "bg-bg", "py-6", "w-screen") }, { children: _jsx("div", __assign({ className: cn("relative", "flex", "justify-center", "items-center", "w-full", "max-w-320", "mx-auto", "px-16") }, { children: _jsx(BrandLogo, { className: cn("h-[4.5rem]"), containerClassName: cn("z-10") }, void 0) }), void 0) }), void 0));
});
var StaticContentMobileHeader = React.memo(function () {
    return (_jsx("div", __assign({ className: cn("flex xl:hidden", "justify-center", "items-end", "w-screen", "bg-green", "h-21", "py-3", "px-4", "rounded-b-xl") }, { children: _jsxs("div", { children: [_jsx("img", { src: assetURL("mobile-home-logo.svg"), className: "mx-auto w-[50px] h-[40px] object-contain" }, void 0),
                _jsx("span", __assign({ className: cn("block mt-2 mx-auto", "text-xs leading-[0.5625rem] text-white font-bold", "uppercase") }, { children: _jsx(LocalisedText, { id: "common.siteName" }, void 0) }), void 0)] }, void 0) }), void 0));
});
export var StaticContentRoute = React.memo(function (props) {
    return (_jsxs("div", __assign({ className: cn("block", "bg-bg", "flex", "flex-col", "flex-expand", "isolate", "relative") }, { children: [_jsxs("header", __assign({ className: cn("block xl:static", "sticky top-0", "z-20 xl:z-auto") }, { children: [_jsx(StaticContentDesktopHeader, {}, void 0),
                    _jsx(StaticContentMobileHeader, {}, void 0)] }), void 0),
            _jsx("main", __assign({ className: cn("flex-expand", "flex", "flex-col") }, { children: props.children }), void 0),
            _jsx("footer", __assign({ className: cn("block xl:static", "sticky bottom-0", "z-20 xl:z-auto") }, { children: _jsx(DesktopFooter, {}, void 0) }), void 0)] }), void 0));
});
