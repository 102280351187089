import { jsx as _jsx } from "react/jsx-runtime";
import "./publicPath";
import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import App from "./App";
if (window.appConfig.sentryDsn) {
    Sentry.init({
        dsn: window.appConfig.sentryDsn,
        integrations: [
            new CaptureConsole({
                levels: ["error"],
            }),
        ],
    });
}
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(App, {}, void 0) }, void 0), document.getElementById("root"));
