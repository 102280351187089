var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var APIResponseErrorType;
(function (APIResponseErrorType) {
    APIResponseErrorType["APIAuthenticationError"] = "APIAuthenticationError";
    APIResponseErrorType["APIInvalidResponseError"] = "APIInvalidResponseError";
    APIResponseErrorType["APIUnknownError"] = "APIUnknownError";
})(APIResponseErrorType || (APIResponseErrorType = {}));
var APIResponseError = /** @class */ (function (_super) {
    __extends(APIResponseError, _super);
    function APIResponseError(response, errorType) {
        var _this = _super.call(this, String(response.status)) || this;
        _this.response = response;
        _this.type = errorType;
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, APIResponseError.prototype);
        return _this;
    }
    return APIResponseError;
}(Error));
export { APIResponseError };
var APIAuthenticationError = /** @class */ (function (_super) {
    __extends(APIAuthenticationError, _super);
    function APIAuthenticationError(response) {
        var _this = _super.call(this, response, APIResponseErrorType.APIAuthenticationError) || this;
        _this.response = response;
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, APIAuthenticationError.prototype);
        return _this;
    }
    return APIAuthenticationError;
}(APIResponseError));
export { APIAuthenticationError };
var APIInvalidResponseError = /** @class */ (function (_super) {
    __extends(APIInvalidResponseError, _super);
    function APIInvalidResponseError(response, validationError) {
        var _this = _super.call(this, response, APIResponseErrorType.APIInvalidResponseError) || this;
        _this.validationError = validationError;
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, APIInvalidResponseError.prototype);
        return _this;
    }
    return APIInvalidResponseError;
}(APIResponseError));
export { APIInvalidResponseError };
var APIUnknownError = /** @class */ (function (_super) {
    __extends(APIUnknownError, _super);
    function APIUnknownError(response) {
        var _this = _super.call(this, response, APIResponseErrorType.APIUnknownError) || this;
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, APIUnknownError.prototype);
        return _this;
    }
    return APIUnknownError;
}(APIResponseError));
export { APIUnknownError };
