var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useMeUserRepository } from "@repositories/MeUserRepository";
import { OAuthContext } from "./OAuthContext";
import { APIAuthenticationError } from "@models/apiResponseErrors";
var AuthContext = React.createContext(null);
export var AuthContextProvider = function (props) {
    var apiClient = props.apiClient;
    var history = useHistory();
    var oauthState = useContext(OAuthContext).oauthState;
    var _a = useMeUserRepository(function (repo) { return ({
        meUser: repo.meUser,
        getMeUser: repo.getMeUser,
        setMeUser: repo.setMeUser,
    }); }), meUser = _a.meUser, getMeUser = _a.getMeUser, setMeUser = _a.setMeUser;
    var oauthStateRef = useRef(oauthState);
    oauthStateRef.current = oauthState;
    var _b = useState(false), initialized = _b[0], setInitialized = _b[1];
    var checkMeUser = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var isLoggedInWithAuthgear, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isLoggedInWithAuthgear = apiClient.getAuthgearContainer().sessionState === "AUTHENTICATED";
                    if (!isLoggedInWithAuthgear) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getMeUser()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setInitialized(true);
                    setMeUser(null);
                    // expect unauthorized error
                    if (!(e_1 instanceof APIAuthenticationError)) {
                        console.error(e_1);
                    }
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    setMeUser(null);
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); }, [apiClient, getMeUser, setMeUser]);
    useEffect(function () {
        var authgearDelegate = {
            onSessionStateChange: function () {
                checkMeUser()
                    .then(function () {
                    setInitialized(true);
                })
                    .catch(function (e) {
                    setInitialized(true);
                    console.error(e);
                });
            },
        };
        apiClient.setAuthgearContainerDelegate(authgearDelegate);
        return function () {
            apiClient.setAuthgearContainerDelegate(null);
        };
    }, [apiClient, checkMeUser]);
    useEffect(function () {
        apiClient.configure().catch(function (e) {
            console.error(e);
        });
    }, [apiClient]);
    var showLoginUI = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiClient.showAuthUI("login")];
        });
    }); }, [apiClient]);
    var showSignupUI = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiClient.showAuthUI("signup")];
        });
    }); }, [apiClient]);
    var signupAsGuest = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiClient.signupAnonymously()];
        });
    }); }, [apiClient]);
    var getSignupUrl = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiClient.makeAuthUrl("signup")];
        });
    }); }, [apiClient]);
    var promoteGuest = useCallback(function (redirectPath) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiClient.promoteAnonymousUser(redirectPath)];
        });
    }); }, [apiClient]);
    var logout = useCallback(function (logoutOption) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, apiClient.logout(logoutOption === null || logoutOption === void 0 ? void 0 : logoutOption.redirectURL)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    // NOTE: silent error if failed
                    // so user will always be redirected to public page
                    // after logout as user is already logged out with Authgear
                    console.error(err_1);
                    return [3 /*break*/, 3];
                case 3:
                    if ((logoutOption === null || logoutOption === void 0 ? void 0 : logoutOption.redirectURL) != null) {
                        history.replace(logoutOption.redirectURL);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [apiClient, history]);
    var openAuthgearSettings = useCallback(function () {
        apiClient.openAuthgearSettings();
    }, [apiClient]);
    // NOTE: Update User ID for Sentry log
    useEffect(function () {
        var currentUser = meUser.state === "loaded"
            ? meUser.data
            : meUser.state === "loading"
                ? meUser.prevData
                : null;
        if (currentUser != null) {
            Sentry.setUser({
                id: currentUser.id,
            });
        }
        else {
            Sentry.setUser(null);
        }
    }, [meUser]);
    var meUserUnwrapped = useMemo(function () {
        var _a;
        if (meUser.state === "loaded") {
            return meUser.data;
        }
        if (meUser.state === "loading") {
            return (_a = meUser.prevData) !== null && _a !== void 0 ? _a : null;
        }
        return null;
    }, [meUser]);
    var value = useMemo(function () { return ({
        checkMeUser: checkMeUser,
        showLoginUI: showLoginUI,
        showSignupUI: showSignupUI,
        getSignupUrl: getSignupUrl,
        logout: logout,
        signupAsGuest: signupAsGuest,
        openAuthgearSettings: openAuthgearSettings,
        promoteGuest: promoteGuest,
        // NOTE: isLoggedIn here means logged in with Whisky Platform
        // with MeUser not null
        isLoggedIn: meUserUnwrapped != null,
        meUserUnwrapped: meUserUnwrapped,
        meUser: meUser,
    }); }, [
        checkMeUser,
        showLoginUI,
        showSignupUI,
        getSignupUrl,
        logout,
        signupAsGuest,
        openAuthgearSettings,
        promoteGuest,
        meUserUnwrapped,
        meUser,
    ]);
    if (!initialized) {
        return null;
    }
    return (_jsx(AuthContext.Provider, __assign({ value: value }, { children: props.children }), void 0));
};
export function useAuth() {
    return React.useContext(AuthContext);
}
