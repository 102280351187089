import { z } from "zod";
export var assetSchema = z.object({
    id: z.string(),
    object_id: z.string(),
    type: z.string().nullable(),
    filename: z.string().nullable(),
    created_at: z.string(),
    url: z.string(),
});
export var getPresignUploadUrlSchema = z.object({
    upload_url: z.string(),
    headers: z.record(z.string(), z.string()),
    asset: assetSchema,
});
