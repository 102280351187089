var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { HomeScreenPath } from "@router/routes";
import { assetURL } from "@utils/assets";
var BrandLogo = React.memo(function (props) {
    var className = props.className, containerClassName = props.containerClassName, _a = props.variant, variant = _a === void 0 ? "link" : _a;
    if (variant === "logo") {
        return (_jsx("div", __assign({ className: containerClassName }, { children: _jsx("img", { className: cn(className), src: assetURL("brand-logo.svg") }, void 0) }), void 0));
    }
    return (_jsx(Link, __assign({ className: containerClassName, to: HomeScreenPath.render() }, { children: _jsx("img", { className: cn(className), src: assetURL("brand-logo.svg") }, void 0) }), void 0));
});
export default BrandLogo;
