import { z } from "zod";
export var paginationParamsSchema = z.object({
    first: z.number().optional(),
    after: z.number().optional(),
});
export function paginationResponseSchema(item) {
    return z.object({
        total: z.number(),
        items: z.array(item),
        cursor: z.number().nullable(),
    });
}
