var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from "classnames";
import { StaticContent } from "@components/common/StaticContent";
import { ScreenLayoutShell } from "@components/common/ScreenLayoutShell";
import { LocalisedText } from "@components/i18n/LocalisedText";
var PrivacyPolicyScreen = function () {
    return (_jsxs(ScreenLayoutShell, __assign({ className: cn("p-6 xl:p-0", "w-full", "xl:mx-auto", "min-h-full", "overflow-hidden", "flex flex-col", "justify-center") }, { children: [_jsx("h2", __assign({ className: cn("block", "text-body-l-bold text-green uppercase", "mb-9") }, { children: _jsx(LocalisedText, { id: "privacyPolicyScreen.title" }, void 0) }), void 0),
            _jsx(StaticContent, { className: cn("flex-1", "w-full"), contentKey: "privacy-policy" }, void 0)] }), void 0));
};
export default PrivacyPolicyScreen;
