import { z } from "zod";
import { tierSchema } from "@models/tier";
import { imageWithThumbnailSchema } from "@models/image";
import { Permission } from "@models/permission";
export var userSchema = z.object({
    id: z.string(),
    username: z.string(),
    email: z.string().nullable(),
    tier: tierSchema,
    is_guest: z.boolean(),
    profile_pic: imageWithThumbnailSchema.nullable(),
});
export var meUserSchema = userSchema.extend({
    note_count: z.number().int(),
    permissions: z.array(Permission),
});
export var getMeUserSchema = z.object({
    me: meUserSchema.nullable(),
});
export var getUserSchema = z.object({
    user: userSchema,
});
export var updateMeUserRequestSchema = z.object({
    profile_pic_id: z.string().uuid().nullable(),
    username: z.string(),
});
export var updateMeUserResponseSchema = z.object({
    me_user: meUserSchema,
});
