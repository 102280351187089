var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { APIClient } from "@api/APIClient";
import { APIContextProvider } from "@api/APIContext";
import { AuthContextProvider } from "@contexts/AuthContext";
import { OAuthContextProvider } from "@contexts/OAuthContext";
import { I18nProvider } from "@contexts/I18nContext";
import { NavigationContextProvider } from "@contexts/NavigationContext";
import { OverlayContextProvider } from "@contexts/OverlayContext";
import AppNavigator from "@router/Navigator";
import { AppRouter } from "@router/AppRouter";
import { PrivacyPolicyPath, TermsAndConditionsPath } from "@router/routes";
import { RepositoryProvider } from "@repositories/RepositoryProvider";
import { StaticContentRoute } from "@components/common/StaticContentRoute";
import ErrorScreen from "@components/screens/ErrorScreen";
import PrivacyPolicyScreen from "@components/screens/PrivacyPolicyScreen";
import TermsAndConditionsScreen from "@components/screens/TermsAndConditionsScreen";
import "./App.scss";
import "./styles/text-styles.css";
// For swiper JS
import "swiper/css";
var App = function () {
    var apiClient = useState(function () { return new APIClient(); })[0];
    return (_jsx(Sentry.ErrorBoundary, __assign({ fallback: _jsx(ErrorScreen, {}, void 0) }, { children: _jsx(OAuthContextProvider, { children: _jsx(OverlayContextProvider, { children: _jsx(APIContextProvider, __assign({ apiClient: apiClient, apiEndpoint: window.appConfig.apiEndpoint }, { children: _jsx(I18nProvider, { children: _jsx(AppRouter, { children: _jsxs(Switch, { children: [_jsx(Route, __assign({ path: PrivacyPolicyPath.pathDefinition, exact: true }, { children: _jsx(StaticContentRoute, { children: _jsx(PrivacyPolicyScreen, {}, void 0) }, void 0) }), void 0),
                                    _jsx(Route, __assign({ path: TermsAndConditionsPath.pathDefinition, exact: true }, { children: _jsx(StaticContentRoute, { children: _jsx(TermsAndConditionsScreen, {}, void 0) }, void 0) }), void 0),
                                    _jsx(Route, { children: _jsx(NavigationContextProvider, { children: _jsx(RepositoryProvider, { children: _jsx(AuthContextProvider, __assign({ apiClient: apiClient }, { children: _jsx(AppNavigator, {}, void 0) }), void 0) }, void 0) }, void 0) }, void 0)] }, void 0) }, void 0) }, void 0) }), void 0) }, void 0) }, void 0) }), void 0));
};
export default App;
