var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { assetURL } from "@utils/assets";
export var UnknownErrorView = React.memo(function (_a) {
    var className = _a.className;
    return (_jsxs("div", __assign({ className: className }, { children: [_jsx("img", { className: "mx-auto w-20", src: assetURL("error-unknown.svg") }, void 0),
            _jsx("h1", __assign({ className: "text-center mt-6 text-section-header" }, { children: _jsx(FormattedMessage, { id: "error.unknown" }, void 0) }), void 0),
            _jsx("p", __assign({ className: "text-center text-body-l mt-2.5" }, { children: _jsx(FormattedMessage, { id: "error.unknown.desc" }, void 0) }), void 0)] }), void 0));
});
