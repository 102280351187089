import { z } from "zod";
import parseISO from "date-fns/parseISO";
export var date = z
    .string()
    .transform(function (val) {
    return parseISO(val);
})
    .refine(function (date) {
    return Number.isFinite(date.getDate());
});
