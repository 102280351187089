var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, useContext, } from "react";
import { useHistory } from "react-router-dom";
import { HomeScreenPath } from "@router/routes";
export var NavigationContext = React.createContext(null);
export var NavigationContextProvider = function (props) {
    var history = useHistory();
    // NOTE: add initial location
    var _a = useState([
        history.location,
    ]), locationStack = _a[0], setLocationStack = _a[1];
    // NOTE: update stack on location change with given navigation action
    useEffect(function () {
        var onAppLocationChange = function (location, action) {
            switch (action) {
                case "PUSH":
                    setLocationStack(function (prev) { return __spreadArrays(prev, [location]); });
                    break;
                case "POP":
                    setLocationStack(function (prev) {
                        // NOTE: Since we are navigating back, the stack should
                        // have at least one location after popped. If not, we attempt
                        // to fix by returning a fresh stack.
                        if (prev.length < 2) {
                            return [location];
                        }
                        var poppedStack = prev.slice(0, -1);
                        var poppedStackTop = poppedStack[poppedStack.length - 1];
                        // NOTE: ensure top of stack match with updated location
                        if (poppedStackTop.pathname !== location.pathname) {
                            return [location];
                        }
                        return poppedStack;
                    });
                    break;
                case "REPLACE":
                    setLocationStack(function (prev) {
                        return __spreadArrays(prev.slice(0, -1), [location]);
                    });
            }
        };
        var historyListenerRemover = history.listen(onAppLocationChange);
        return function () {
            historyListenerRemover();
        };
    }, [history]);
    // NOTE: stack size = 1 => only current page
    // stack size >= 2 => current page on top of stack, hence previous page exist
    var previousLocation = locationStack.length >= 2 ? locationStack[locationStack.length - 2] : null;
    var previousLocationRef = useRef(previousLocation);
    previousLocationRef.current = previousLocation;
    var inAppGoBack = useCallback(function () {
        if (previousLocationRef.current != null) {
            // NOTE: go back if previous location exist
            // stack will be updated by useEffect above
            history.goBack();
        }
        else {
            history.push(HomeScreenPath.render());
        }
    }, [history]);
    var value = useMemo(function () {
        return {
            inAppLocationStack: locationStack,
            previousLocation: previousLocation,
            inAppGoBack: inAppGoBack,
        };
    }, [locationStack, previousLocation, inAppGoBack]);
    return (_jsx(NavigationContext.Provider, __assign({ value: value }, { children: props.children }), void 0));
};
export function useNavigationContext() {
    return useContext(NavigationContext);
}
