import { z } from "zod";
import { date } from "@models/date";
import { imageWithThumbnailSchema } from "@models/image";
import { customFlavorWithScoreSchema } from "@models/customFlavor";
import { userSchema } from "@models/user";
import { paginationParamsSchema, paginationResponseSchema, } from "@models/pagination";
export var tastingNoteSchema = z.object({
    id: z.number().int(),
    created_at: date,
    created_by: userSchema,
    user_tier: z.string(),
    beverage_id: z.number().int(),
    photos: z.array(imageWithThumbnailSchema),
    mood_tags: z.array(z.string()),
    title: z.string(),
    nose: z.string().nullable(),
    palate: z.string().nullable(),
    finish: z.string().nullable(),
    comment: z.string().nullable(),
    rating: z.number().int(),
    is_public: z.boolean(),
    // Flavor profile
    mood: z.string(),
    woody_score: z.number().int(),
    spicy_score: z.number().int(),
    cereal_score: z.number().int(),
    fruity_score: z.number().int(),
    floral_score: z.number().int(),
    peaty_score: z.number().int(),
    custom_flavors: z.array(customFlavorWithScoreSchema),
});
export var createTastingNoteResponseSchema = z.object({
    note: tastingNoteSchema,
});
export var getTastingNoteByIdResponseSchema = z.object({
    note: tastingNoteSchema,
});
export var getTastingNoteRecommendationsResponseSchema = z.object({
    notes: z.array(tastingNoteSchema),
    total: z.number().int(),
});
export var listTastingNotesQuerySchema = z
    .object({
    order_by: z.enum(["created_at", "user_tier", "beverage_name"]).optional(),
    beverage_id: z.number().optional(),
    author_user_id: z.string().uuid().optional(),
})
    .merge(paginationParamsSchema);
export var listTastingNotesResponseSchema = z
    .object({})
    .merge(paginationResponseSchema(tastingNoteSchema));
