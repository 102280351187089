import { z } from "zod";
export var customFlavorSchema = z.object({
    id: z.number(),
    name: z.string(),
});
export var searchCustomFlavorResponseSchema = z.object({
    custom_flavors: z.array(customFlavorSchema),
});
export var customFlavorWithScoreSchema = z.object({
    id: z.number(),
    name: z.string(),
    score: z.number(),
});
