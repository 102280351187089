var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { AddNoteScreenPath, HomeScreenPath, MyFlavorProfileScreenPath, MyProfileScreenPath, WhiskyDetailScreenPath, WhiskyDetailNotesPath, SearchScreenPath, SearchResultScreenPath, TastingNoteDetailScreenPath, UserProfileScreenPath, OAuthRedirectPath, GuestPromotionRedirectPath, EditMyProfileScreenPath, } from "@router/routes";
import OAuthRedirect from "@components/screens/OAuthRedirect";
import GuestPromotion from "@components/screens/GuestPromotion";
var MainRoute = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/common/MainRoute")];
}); }); });
var MyProfileScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/MyProfileScreen")];
}); }); });
var EditMyProfileScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/EditMyProfileScreen")];
}); }); });
var SearchScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/SearchScreen")];
}); }); });
var SearchResultScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/SearchResultScreen")];
}); }); });
var MyFlavorProfileScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/MyFlavorProfileScreen")];
}); }); });
var AddNoteScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/AddNoteScreen")];
}); }); });
var NotFoundScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/NotFoundScreen")];
}); }); });
var WhiskyDetailScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/WhiskyDetailScreen")];
}); }); });
var WhiskyNotesScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/WhiskyNotesScreen")];
}); }); });
var TastingNoteDetailScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/TastingNoteDetailScreen")];
}); }); });
var UserProfileScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/UserProfileScreen")];
}); }); });
var AppNavigator = React.memo(function () {
    return (_jsx(Suspense, __assign({ fallback: null }, { children: _jsxs(Switch, { children: [_jsx(Route, __assign({ path: OAuthRedirectPath.pathDefinition, exact: true }, { children: _jsx(OAuthRedirect, {}, void 0) }), void 0),
                _jsx(Route, __assign({ path: GuestPromotionRedirectPath.pathDefinition, exact: true }, { children: _jsx(GuestPromotion, {}, void 0) }), void 0),
                _jsx(Route, __assign({ path: [
                        HomeScreenPath.pathDefinition,
                        SearchScreenPath.pathDefinition,
                    ], exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "home" }, { children: _jsx(SearchScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: SearchResultScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "title", defaultTitleId: "search.results" }, { children: _jsx(SearchResultScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: MyProfileScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "none" }, { children: _jsx(MyProfileScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: EditMyProfileScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "title", defaultTitleId: "editMyProfileScreen.title" }, { children: _jsx(EditMyProfileScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: MyFlavorProfileScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "title", defaultTitleId: "myFlavorProfileScreen.title" }, { children: _jsx(MyFlavorProfileScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: AddNoteScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "title", defaultTitleId: "addNotesScreen.title" }, { children: _jsx(AddNoteScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: WhiskyDetailScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "title", defaultTitleId: "whiskyDetailScreen.title" }, { children: _jsx(WhiskyDetailScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: WhiskyDetailNotesPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "title", defaultTitleId: "whiskyNotesScreen.title" }, { children: _jsx(WhiskyNotesScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: TastingNoteDetailScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "none" }, { children: _jsx(TastingNoteDetailScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: UserProfileScreenPath.pathDefinition, exact: true }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "none" }, { children: _jsx(UserProfileScreen, {}, void 0) }), void 0) }), void 0),
                _jsx(Route, __assign({ path: "*" }, { children: _jsx(MainRoute, __assign({ mobileHeaderType: "none" }, { children: _jsx(NotFoundScreen, {}, void 0) }), void 0) }), void 0)] }, void 0) }), void 0));
});
export default AppNavigator;
