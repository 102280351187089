var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
export var OAuthContext = React.createContext(null);
export var OAuthContextProvider = function (props) {
    var _a = useState(null), oauthState = _a[0], setOauthState = _a[1];
    var value = useMemo(function () { return ({
        setOauthState: setOauthState,
        oauthState: oauthState,
    }); }, [oauthState]);
    return (_jsx(OAuthContext.Provider, __assign({ value: value }, { children: props.children }), void 0));
};
