var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useMemo, useContext } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
var AppRouterContext = React.createContext(undefined);
export var AppRouter = React.memo(function (_a) {
    var children = _a.children;
    var _b = useState(null), blocker = _b[0], setBlocker = _b[1];
    var history = useState(function () {
        return createBrowserHistory({
            getUserConfirmation: function (message, callback) {
                var wrappedCallback = function (result) {
                    setBlocker(null);
                    callback(result);
                };
                setBlocker({ message: message, callback: wrappedCallback });
            },
        });
    })[0];
    var value = useMemo(function () {
        return {
            navigationBlocker: blocker,
        };
    }, [blocker]);
    return (_jsx(Router, __assign({ history: history }, { children: _jsx(AppRouterContext.Provider, __assign({ value: value }, { children: children }), void 0) }), void 0));
});
export function useAppRouter() {
    return useContext(AppRouterContext);
}
