import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DistilleryRepository } from "@repositories/DistilleryRepository";
import { OriginRepository } from "@repositories/OriginRepository";
import { CategoryRepository } from "@repositories/CategoryRepository";
import { MoodRepository } from "@repositories/MoodRepository";
import { TierRepository } from "@repositories/TierRepository";
import { BeverageRepository } from "@repositories/BeverageRepository";
import { TastingNoteRepository } from "@repositories/TastingNoteRepository";
import { UserRepository } from "./UserRepository";
import { MeUserRepository } from "./MeUserRepository";
export var RepositoryProvider = React.memo(function (_a) {
    var children = _a.children;
    return (_jsx(MeUserRepository, { children: _jsx(UserRepository, { children: _jsx(DistilleryRepository, { children: _jsx(OriginRepository, { children: _jsx(CategoryRepository, { children: _jsx(MoodRepository, { children: _jsx(TierRepository, { children: _jsx(BeverageRepository, { children: _jsx(TastingNoteRepository, { children: children }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0));
});
