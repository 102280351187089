import { z } from "zod";
export var categorySchema = z.object({
    slug: z.string(),
    display_name: z.string(),
});
export var getCategoriesParamsSchema = z.object({
    slug: z.array(z.string()).optional(),
});
export var getCategoriesResponseSchema = z.object({
    categories: z.array(categorySchema),
});
