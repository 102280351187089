function makeRouteDefinition(pathDefinition, render) {
    return {
        pathDefinition: pathDefinition,
        render: render,
    };
}
function makeStaticRouteDefinition(pathDefinition) {
    return makeRouteDefinition(pathDefinition, function () { return pathDefinition; });
}
export var PrivacyPolicyPath = makeStaticRouteDefinition("/privacy-policy");
export var TermsAndConditionsPath = makeStaticRouteDefinition("/terms-and-conditions");
export var OAuthRedirectPath = makeStaticRouteDefinition("/oauth-redirect");
export var GuestPromotionRedirectPath = makeStaticRouteDefinition("/guest-promotion");
export var HomeScreenPath = makeStaticRouteDefinition("/");
export var MyProfileScreenPath = makeStaticRouteDefinition("/me/profile");
export var EditMyProfileScreenPath = makeStaticRouteDefinition("/me/profile/edit");
export var UserProfileScreenPath = makeRouteDefinition("/users/:userId/profile", function (userId) { return "/users/" + userId + "/profile"; });
export var MyFlavorProfileScreenPath = makeStaticRouteDefinition("/me/profile/flavor");
export var AddNoteScreenPath = makeStaticRouteDefinition("/notes/add");
export var WhiskyDetailScreenPath = makeRouteDefinition("/whiskies/:beverageId", function (beverageId) { return "/whiskies/" + beverageId; });
export var WhiskyDetailNotesPath = makeRouteDefinition("/whiskies/:beverageId/notes", function (beverageId) { return "/whiskies/" + beverageId + "/notes"; });
export var SearchScreenPath = makeStaticRouteDefinition("/search");
export var SearchResultScreenPath = makeStaticRouteDefinition("/search/results");
export var TastingNoteDetailScreenPath = makeRouteDefinition("/whiskies/:beverageId/notes/:noteId", function (beverageId, noteId) {
    return "/whiskies/" + beverageId + "/notes/" + noteId;
});
