var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo } from "react";
export var OverlayContext = React.createContext(undefined);
export var OverlayContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var _b = useState(null), containerEl = _b[0], setContainerEl = _b[1];
    var value = useMemo(function () {
        return {
            containerEl: containerEl,
        };
    }, [containerEl]);
    return (_jsxs(_Fragment, { children: [_jsx(OverlayContext.Provider, __assign({ value: value }, { children: children }), void 0),
            _jsx("div", { className: "fixed top-0 left-0 bottom-0 right-0 pointer-events-none isolate", ref: setContainerEl }, void 0)] }, void 0));
});
