var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../../styles/loading-animation.json";
export var LoadingSpinner = React.memo(function (props) {
    var className = props.className;
    return (_jsx("div", __assign({ className: className }, { children: _jsx(Lottie
        // NOTE: workaround missing props error
        , { 
            // NOTE: workaround missing props error
            container: null, loop: true, autoplay: true, animationData: loadingAnimation }, void 0) }), void 0));
});
export default LoadingSpinner;
